import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ContactMap from '../../components/ContactMap/ContactMap';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import ManagedSolutions from '../../components/ManagedSolutions/ManagedSolutions';
import './Sitemap.scss';

export default function Sitemap() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="sitemap">
      <Helmet>
        <title>Sitemap - inte-QT</title>
        <meta name="title" content="Sitemap - inte-QT" />
        <meta
          name="description"
          content="Sitemap of inte-QT where you can see all of our url's."
        />
        <link rel="canonical" href="https://www.inte-qt.com/sitemap" />
      </Helmet>
      <ContactMap />
      <h1 className="text-center center-header mx-auto m-5">Sitemap</h1>
      <div className="text-center mb-5 container">
        <p>
          <Link to="/">https://www.inte-qt.com/</Link>
        </p>
        <p>
          {' '}
          <Link to="/coverage">https://www.inte-qt.com/coverage</Link>
        </p>
        <p>
          {' '}
          <Link to="/services">https://www.inte-qt.com/services</Link>
        </p>
        <p>
          {' '}
          <Link to="/services/dedicated-lines">
            https://www.inte-qt.com/services/dedicated-lines
          </Link>
        </p>
        <p>
          {' '}
          <Link to="/services/wireless-connect">
            https://www.inte-qt.com/services/wireless-connect
          </Link>
        </p>
        <p>
          {' '}
          <Link to="/services/business-broadband">
            https://www.inte-qt.com/services/business-broadband
          </Link>
        </p>
        <p>
          {' '}
          <Link to="/partner-center">
            https://www.inte-qt.com/partner-center
          </Link>
        </p>
        <p>
          {' '}
          <Link to="/cases">https://www.inte-qt.com/cases</Link>
        </p>
        <p>
          {' '}
          <Link to="/cases/fiji">https://www.inte-qt.com/cases/fiji</Link>
        </p>
        <p>
          {' '}
          <Link to="/cases/lebanon">https://www.inte-qt.com/cases/lebanon</Link>
        </p>
        <p>
          {' '}
          <Link to="/cases/argentina">https://www.inte-qt.com/cases/argentina</Link>
        </p>
        <p>
          {' '}
          <Link to="/contact">https://www.inte-qt.com/contact</Link>
        </p>
        <p>
          {' '}
          <Link to="/contact/support">
            https://www.inte-qt.com/contact/support
          </Link>
        </p>
        <p>
          {' '}
          <Link to="/contact/general">
            https://www.inte-qt.com/contact/general
          </Link>
        </p>
        <p>
          {' '}
          <Link to="/contact/sales">
            https://www.inte-qt.com/contact/sales
          </Link>
        </p>
        <p>
          {' '}
          <Link to="/contact/partners">https://www.inte-qt.com/contact/partners</Link>
        </p>
        <p>
          {' '}
          <Link to="/contact/quote">https://www.inte-qt.com/contact/quote</Link>
        </p>
        <p>
          {' '}
          <Link to="/global-nsoc">https://www.inte-qt.com/global-nsoc</Link>
        </p>
        <p>
          {' '}
          <Link to="/blog">https://www.inte-qt.com/blog</Link>
        </p>
        <p>
          {' '}
          <Link to="/blog/1">https://www.inte-qt.com/blog/1</Link>
        </p>
        <p>
          {' '}
          <Link to="/blog/2">https://www.inte-qt.com/blog/2</Link>
        </p>
        <p>
          {' '}
          <Link to="/blog/3">https://www.inte-qt.com/blog/3</Link>
        </p>
        <p>
          {' '}
          <Link to="/blog/4">https://www.inte-qt.com/blog/4</Link>
        </p>
        <p>
          {' '}
          <Link to="/blog/5">https://www.inte-qt.com/blog/5</Link>
        </p>
         <p>
          {' '}
          <Link to="/blog/5">https://www.inte-qt.com/blog/6</Link>
        </p>
         <p>
          {' '}
          <Link to="/blog/5">https://www.inte-qt.com/blog/7</Link>
        </p>
         <p>
          {' '}
          <Link to="/blog/5">https://www.inte-qt.com/blog/8</Link>
        </p>
        <p>
          {' '}
          <Link to="/blogFile">https://www.inte-qt.com/blogFile</Link>
        </p>
        <p>
          {' '}
          <Link to="/auth">https://www.inte-qt.com/auth</Link>
        </p>
        <p>
          {' '}
          <Link to="/events">https://www.inte-qt.com/events</Link>
        </p>
        <p>
          {' '}
          <Link to="/privacy-policy">https://www.inte-qt.com/privacy-policy</Link>
        </p>
        <p>
          {' '}
          <Link to="/sitemap">https://www.inte-qt.com/sitemap</Link>
        </p>
        <p>
          {' '}
          <Link to="/testimonials">https://www.inte-qt.com/testimonials</Link>
        </p>
        <p>
          {' '}
          <Link to="/testimonials/mx">https://www.inte-qt.com/testimonials/mx</Link>
        </p>
        <p>
          {' '}
          <Link to="/policy-gtc-aup">https://www.inte-qt.com/policy-gtc-aup</Link>
        </p>
        <p>
          {' '}
          <Link to="/about-us">https://www.inte-qt.com/about-us</Link>
        </p>
      </div>
      <ManagedSolutions />
      <GetInTouch />
    </div>
  );
}
